import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthRedirect } from 'hooks/useAuthRedirect';
import LogoutIcon from '@mui/icons-material/Logout';
import { useUrlParams } from 'hooks/useUrlParams';
import FisherLogo from 'assets/logo/FisherLogo.svg';
import { AccountDetails } from '../AccountDetails/AccountDetails';
import { useAccountDetails } from 'hooks/useAccountDetails';

export default function Header() {
  const navigate = useNavigate();
  const [isSigningOut, setIsSigningOut] = React.useState(false);
  const { signOutRedirect, isAuthenticated } = useAuthRedirect();
  const { params } = useUrlParams();
  const { getAccountDetails } = useAccountDetails();

  const isAccountInfoValid = React.useMemo(() => {
    return !!(
      params.accountDetails &&
      params.accountNumber &&
      params.function === 'cc_consent_add'
    );
  }, [params]);

  React.useEffect(() => {
    if (!isAccountInfoValid && !isSigningOut) {
      navigate('/contact-support');
    }
  }, [isAccountInfoValid, navigate]);

  const accountDetails = getAccountDetails()

  const handleSignOut = () => {
    setIsSigningOut(true);
    signOutRedirect();
  };

  return (
    <Box sx={{ mb: 4, width: '100%', maxWidth: '1200px', mx: 'auto' }}>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        <FisherLogo style={{ height: '60px', width: 'auto' }} />
      </Box>

      <Box sx={{ position: 'relative', mb: 2 }}>
        {isAccountInfoValid && (
          <AccountDetails
            accountDetails={accountDetails?.customerName ?? params.accountDetails}
            accountAddress={accountDetails?.accountAddress}
            city={accountDetails?.city}
            state={accountDetails?.state}
            zip={accountDetails?.zip}
          />
        )}
        
        {isAuthenticated && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSignOut}
            startIcon={<LogoutIcon />}
            sx={{
              position: 'absolute',
              right: 0,
              top: '100%',
              mt: 1
            }}
          >
            Sign Out
          </Button>
        )}
      </Box>
    </Box>
  );
} 