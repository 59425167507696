import axios from 'axios';
import { apiConfig } from '../config/api';


const apiClient = axios.create({
  baseURL: `${apiConfig.baseUrl}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      console.log('Unauthorized - token may be invalid or expired');
    }
    return Promise.reject(error);
  }
);

export default apiClient; 

