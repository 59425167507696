interface APIConfigProps{
  baseUrl: string;
}

export const apiConfig: APIConfigProps = {
  baseUrl: process.env.BACKEND_API_BASE_URL || '',
} as const;

export const getApiUrl = (endpoint: string): string => {
  const normalizedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
  return `${apiConfig.baseUrl}${normalizedEndpoint}`;
};
