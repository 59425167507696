import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useAuthRedirect } from 'hooks/useAuthRedirect';
import { useUrlParams } from 'hooks/useUrlParams';

export default function HomePage() {
  const navigate = useNavigate();
  const { hasValidParams } = useUrlParams({ loadFromUrl: true });
  const { isAuthenticated, isLoading } = useAuthRedirect();
  useEffect(() => {
    if (!isLoading) {
      if (!hasValidParams) {
        navigate('/contact-support');
        return;
    }

      if (!isAuthenticated) {
        navigate('/login');
        return;
      }

      navigate('/consent');
    }
    return;
  }, [hasValidParams, isAuthenticated, isLoading, navigate]);

  return <LoadingSpinner />;
}
