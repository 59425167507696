import React from 'react';
import { Grid, TextField, MenuItem, Box } from '@mui/material';
import { commonTextFieldStyles, commonMicroformStyles } from '../styles';
import { EXPIRY_YEARS } from '../constants';

interface CreditCardSectionProps {
  expiryMonth: string;
  expiryYear: string;
  onExpiryMonthChange: (value: string) => void;
  onExpiryYearChange: (value: string) => void;
  onBlur: (field: string) => void;
  touchedFields: Record<string, boolean>;
}

export const CreditCardSection: React.FC<CreditCardSectionProps> = ({
  expiryMonth,
  expiryYear,
  onExpiryMonthChange,
  onExpiryYearChange,
  onBlur,
  touchedFields
}) => {
  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Box
          id="number-container"
          sx={commonMicroformStyles}
        >
          <span className="microform-label">Credit Card Number</span>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            required
            select
            fullWidth
            label="Month"
            value={expiryMonth}
            onChange={(e) => onExpiryMonthChange(e.target.value)}
            onBlur={() => onBlur('expiryMonth')}
            error={touchedFields.expiryMonth && !expiryMonth}
            helperText={touchedFields.expiryMonth && !expiryMonth ? 'Required' : ''}
            sx={commonTextFieldStyles}
          >
            {Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')).map(month => (
              <MenuItem key={month} value={month}>{month}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            select
            fullWidth
            label="Year"
            value={expiryYear}
            onChange={(e) => onExpiryYearChange(e.target.value)}
            onBlur={() => onBlur('expiryYear')}
            error={touchedFields.expiryYear && !expiryYear}
            helperText={touchedFields.expiryYear && !expiryYear ? 'Required' : ''}
            sx={commonTextFieldStyles}
          >
            {EXPIRY_YEARS.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Box
            id="securityCode-container"
            sx={commonMicroformStyles}
          >
            <span className="microform-label">CVV</span>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}; 