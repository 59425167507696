import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAuthRedirect } from '../hooks/useAuthRedirect';
import { MainLayout } from 'components/layouts/MainLayout';

export default function LoginPage() {
  const { signinRedirect } = useAuthRedirect();

  return (
    <MainLayout>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Welcome to Fisher Auto Parts
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => signinRedirect()}
          sx={{ mt: 2 }}
        >
          Sign in
        </Button>
      </Box>
    </MainLayout>
  );
}
