import React, { Suspense } from 'react';
import { AppRoutes } from './routes';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';

const App: React.FC = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AppRoutes />
    </Suspense>
  );
};

export default App;