interface AccountDetails {
  accountDetails: string;
  accountAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  branch?: string;
  branchAbbreviation?: string;
  customerName?: string;
}

const STORAGE_KEY = 'account_details';

export const useAccountDetails = () => {
  const saveAccountDetails = (details: AccountDetails) => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(details));
  };

  const getAccountDetails = (): AccountDetails | null => {
    const stored = sessionStorage.getItem(STORAGE_KEY);
    if (!stored) return null;
    
    try {
      return JSON.parse(stored);
    } catch {
      return null;
    }
  };

  const clearAccountDetails = () => {
    sessionStorage.removeItem(STORAGE_KEY);
  };

  return {
    saveAccountDetails,
    getAccountDetails,
    clearAccountDetails
  };
}; 