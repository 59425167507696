import React, { useState, useRef } from 'react';
import { Grid, TextField, MenuItem, InputBase } from '@mui/material';
import { commonTextFieldStyles } from '../styles';
import { COUNTRIES, getRegions } from '../constants';

interface AddressSectionProps {
  billingAddress: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  onBillingAddressChange: (value: string) => void;
  onCityChange: (value: string) => void;
  onStateChange: (value: string) => void;
  onZipCodeChange: (value: string) => void;
  onCountryChange: (value: string) => void;
  onBlur: (field: string) => void;
  touchedFields: Record<string, boolean>;
}

export const AddressSection: React.FC<AddressSectionProps> = ({
  billingAddress,
  city,
  state,
  zipCode,
  country,
  onBillingAddressChange,
  onCityChange,
  onStateChange,
  onZipCodeChange,
  onCountryChange,
  onBlur,
  touchedFields
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredCountries = React.useMemo(() => 
    COUNTRIES.filter(country => 
      country.label.toLowerCase().includes(searchTerm)
    ),
    [searchTerm]
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) return;
    
    const key = event.key.toLowerCase();
    if (key.length === 1 && /[a-z]/.test(key)) {
      const countryIndex = COUNTRIES.findIndex(country => 
        country.label.toLowerCase().startsWith(key)
      );
      if (countryIndex !== -1) {
        const selectElement = document.getElementById('address-country-select');
        const optionElement = selectElement?.querySelector(`[data-value="${COUNTRIES[countryIndex].value}"]`);
        optionElement?.scrollIntoView({ block: 'nearest' });
      }
    }
  };

  const getStateHelperText = () => {
    if (!state) return 'Required';
    return '';
  };

  const getZipHelperText = () => {
    if (!zipCode) return 'Required';
    if (!/^\d{5}(-\d{4})?$/.test(zipCode)) return 'Invalid ZIP code format';
    return '';
  };

  const regions = country ? getRegions(country) : null;

  return (
    <>
      <TextField
        required
        select
        fullWidth
        id="address-country-select"
        label="Country"
        value={country}
        onChange={(e) => onCountryChange(e.target.value)}
        onBlur={() => onBlur('country')}
        onKeyDown={(e) => {
          if (document.activeElement === searchInputRef.current) {
            e.stopPropagation();
          } else {
            handleKeyDown(e);
          }
        }}
        sx={{ ...commonTextFieldStyles, mt: 2 }}
        error={touchedFields.country && !country}
        helperText={touchedFields.country && !country ? 'Required' : ''}
        SelectProps={{
          onOpen: () => setIsOpen(true),
          onClose: () => {
            setIsOpen(false);
            setSearchTerm('');
          },
          MenuProps: {
            PaperProps: {
              sx: { maxHeight: 300 }
            },
            onClose: (event: React.SyntheticEvent) => {
              if (document.activeElement === searchInputRef.current) {
                event.preventDefault();
              }
            }
          }
        }}
      >
        <MenuItem 
          sx={{ 
            p: 0, 
            '&:hover': { background: 'none' },
            '&.Mui-selected': { background: 'none' }
          }}
          disableRipple
        >
          <InputBase
            inputRef={searchInputRef}
            placeholder="Search country..."
            value={searchTerm}
            onChange={handleSearch}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            sx={{ 
              px: 2, 
              py: 1,
              width: '100%',
              '& input': {
                padding: 0
              }
            }}
          />
        </MenuItem>
        <MenuItem value="" disabled>
          Select a country
        </MenuItem>
        {(searchTerm ? filteredCountries : COUNTRIES).map(({ value, label }) => (
          <MenuItem 
            key={value} 
            value={value}
            data-value={value}
            onClick={(e) => {
              e.stopPropagation();
              onCountryChange(value);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        required
        fullWidth
        label="Billing Address"
        value={billingAddress}
        onChange={(e) => onBillingAddressChange(e.target.value)}
        onBlur={() => onBlur('billingAddress')}
        error={touchedFields.billingAddress && !billingAddress}
        helperText={touchedFields.billingAddress && !billingAddress ? 'Required' : ''}
        sx={{ ...commonTextFieldStyles, mt: 2 }}
      />

      <TextField
        required
        fullWidth
        label="City"
        value={city}
        onChange={(e) => onCityChange(e.target.value)}
        onBlur={() => onBlur('city')}
        error={touchedFields.city && !city}
        helperText={touchedFields.city && !city ? 'Required' : ''}
        sx={{ ...commonTextFieldStyles, mt: 2 }}
      />

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <TextField
            required
            select={!!regions}
            fullWidth
            label="State/Province"
            value={state}
            onChange={(e) => onStateChange(e.target.value)}
            onBlur={() => onBlur('state')}
            error={touchedFields.state && !state}
            helperText={touchedFields.state ? getStateHelperText() : ''}
            sx={commonTextFieldStyles}
          >
            {regions && Object.entries(regions).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                {name} ({code})
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={8}>
          <TextField
            required
            fullWidth
            label="Zip Code"
            value={zipCode}
            onChange={(e) => onZipCodeChange(e.target.value)}
            onBlur={() => onBlur('zipCode')}
            error={touchedFields.zipCode && (!!zipCode && !/^\d{5}(-\d{4})?$/.test(zipCode))}
            helperText={touchedFields.zipCode ? getZipHelperText() : ''}
            sx={commonTextFieldStyles}
          />
        </Grid>
      </Grid>
    </>
  );
}; 