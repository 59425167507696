import React, { useEffect, useState } from 'react';
import PaymentForm from "components/paymentForm/paymentForm";
import { MainLayout } from 'components/layouts/MainLayout';
import { Typography, Paper } from '@mui/material';
import { useConsentApi } from '../hooks/useConsentApi';
import { useInterval } from '../hooks/useInterval';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useUrlParams } from 'hooks/useUrlParams';

export default function ConsentPage() {
  const { getCyberSourceJwt } = useConsentApi();
  const { params } = useUrlParams();
  const [cyberSourceJWT, setCyberSourceJWT] = useState<string | null>(null);

  const fetchJwt = async () => {
    try {
      const response = await getCyberSourceJwt(params.accountNumber);
      setCyberSourceJWT(response);

      
    } catch (error) {
      console.error('Error fetching JWT:', error);
    }
  };

  useEffect(() => {
    fetchJwt(); 
  }, []);

  useInterval(fetchJwt, 900000); 

  return (
    <MainLayout>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          maxWidth: 500, 
          width: '100%',
          borderRadius: 2,
          marginTop: 1
        }}
      >
        <Typography variant="h5" gutterBottom>
          Save Your Card Information
        </Typography>
        <Typography color="text.secondary" sx={{ mb: 4 }}>
          Make your future orders even smoother by securely saving your credit card information.
        </Typography>
        {cyberSourceJWT ? <PaymentForm contextJWT={cyberSourceJWT} /> : <LoadingSpinner />}
      </Paper>
    </MainLayout>
  );
}
