import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const STORED_PARAMS_KEY = 'app_url_params';

export interface UrlParams {
  function: string;
  accountNumber: string;
  accountDetails: string;
}

const decodeParam = (value: string | null): string | undefined => {
  if (!value) return undefined;
  try {
    return decodeURIComponent(value);
  } catch (error) {
    console.error('Failed to decode URL parameter:', error);
    return undefined;
  }
};

const decodeBase64Param = (value: string | null): string | undefined => {
  if (!value) return undefined;
  try {
    return atob(decodeURIComponent(value));
  } catch (error) {
    console.error('Failed to decode URL parameter:', error);
    return undefined;
  }
};

interface UseUrlParamsOptions {
  loadFromUrl?: boolean;
}

export const useUrlParams = (options: UseUrlParamsOptions = {}) => {
  const { loadFromUrl = false } = options;
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState<UrlParams>(() => {
    const stored = sessionStorage.getItem(STORED_PARAMS_KEY);
    return stored ? JSON.parse(stored) : {};
  });

  useEffect(() => {
    if (!loadFromUrl) return;

    const functionParam = decodeBase64Param(searchParams.get('function'));
    const accountNumber = decodeParam(searchParams.get('account_number'));
    const accountDetails = decodeBase64Param(searchParams.get('account_detail'));

    if (functionParam && accountNumber && accountDetails) {
      const newParams: UrlParams = {
        function: functionParam,
        accountNumber,
        accountDetails
      };

      sessionStorage.setItem(STORED_PARAMS_KEY, JSON.stringify(newParams));
      setParams(newParams);
    }
  }, [loadFromUrl, searchParams]);

  const hasValidParams = !!(
    params.function &&
    params.accountNumber &&
    params.accountDetails
  );

  return {
    params,
    hasValidParams
  };
}; 