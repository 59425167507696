import { useState, useEffect } from 'react';
import { MicroformInstance } from '../types';

interface UseMicroformReturn {
  microform: MicroformInstance | null;
  cardNumberValid: boolean;
  cvvValid: boolean;
  error: string | null;
  createToken: (options: { expirationMonth: string; expirationYear: string }) => Promise<unknown>;
}

export const useMicroform = (contextJWT: string): UseMicroformReturn => {
  const [microform, setMicroform] = useState<MicroformInstance | null>(null);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializeMicroform = async () => {
      try {
        const flex = new window.Flex(contextJWT);
        const microform = flex.microform({
          styles: {
            input: {
              'font-family': '"Roboto","Helvetica","Arial",sans-serif',
              'font-size': '1rem',
              'font-weight': '400',
              'line-height': '1.4375em',
              color: 'rgba(0, 0, 0, 0.87)',
            },
            ':focus': { 
              color: 'rgba(0, 0, 0, 0.87)' 
            },
            ':disabled': { 
              cursor: 'not-allowed',
              color: 'rgba(0, 0, 0, 0.38)'
            },
            valid: { 
              color: '#3c763d' 
            },
            invalid: { 
              color: '#a94442' 
            }
          }
        });

        const number = microform.createField('number', { 
          placeholder: 'Credit Card Number' 
        });
        number.on('change', (data: {valid: boolean}) => {
          setCardNumberValid(data.valid);
        });

        const securityCode = microform.createField('securityCode', { 
          placeholder: 'CVV' 
        });
        securityCode.on('change', (data: {valid: boolean}) => {
          setCvvValid(data.valid);
        });

        await Promise.all([
          number.load('#number-container'),
          securityCode.load('#securityCode-container')
        ]);

        setMicroform(microform);
      } catch (err) {
        console.error('Microform initialization error:', err);
        setError('Failed to initialize payment form');
      }
    };

    initializeMicroform();
  }, [contextJWT]);

  const createToken = async (options: { expirationMonth: string; expirationYear: string }) => {
    if (!microform) throw new Error('Microform not initialized');

    return new Promise((resolve, reject) => {
      microform.createToken(options, (err, token) => {
        if (err) reject(err);
        else resolve(token);
      });
    });
  };

  return {
    microform,
    cardNumberValid,
    cvvValid,
    error,
    createToken
  };
}; 