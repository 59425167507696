import { useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';
import { getRegions } from '../constants';

export interface FormFields {
  firstName: string;
  lastName: string;
  billingAddress: string;
  city: string;
  state: string;
  zipCode: string;
  expiryMonth: string;
  expiryYear: string;
  cardNickname: string;
  country: string;
  phoneNumber: string;
  email: string;
}

export const useFormValidation = () => {
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>({
    firstName: false,
    lastName: false,
    billingAddress: false,
    city: false,
    state: false,
    zipCode: false,
    cardNickname: false,
    country: false,
    expiryMonth: false,
    expiryYear: false,
    phoneNumber: false,
    email: false
  });

  const handleBlur = (field: string) => {
    setTouchedFields(prev => ({
      ...prev,
      [field]: true
    }));
  };

  const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhone = (phone: string, countryCode: CountryCode): boolean => {
    try {
      const phoneNumber = parsePhoneNumber(phone, { defaultCountry: countryCode });
      return phoneNumber.isValid();
    } catch {
      return false;
    }
  };

  const isValidForm = (fields: FormFields, cardNumberValid: boolean, cvvValid: boolean, agreed: boolean): boolean => {
    const hasEmptyFields = Object.values(fields).some(
      field => !field || field.toString().trim() === ''
    );
    const isValidZip = /^\d{5}(-\d{4})?$/.test(fields.zipCode);
    
    const isValidState = () => {
      if (!fields.state.trim()) return false;
      
      const regions = getRegions(fields.country);
      if (regions) {
        return Object.keys(regions).includes(fields.state);
      }
      return true;
    };

    const hasValidExpiryDate = () => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      
      const selectedYear = parseInt(fields.expiryYear);
      const selectedMonth = parseInt(fields.expiryMonth);
      
      if (selectedYear < currentYear) return false;
      if (selectedYear === currentYear && selectedMonth < currentMonth) return false;
      return true;
    };

    return (
      !hasEmptyFields &&
      isValidZip &&
      isValidState() &&
      hasValidExpiryDate() &&
      isValidEmail(fields.email) &&
      agreed &&
      cardNumberValid &&
      cvvValid
    );
  };

  return {
    touchedFields,
    handleBlur,
    isValidEmail,
    isValidPhone,
    isValidForm
  };
}; 