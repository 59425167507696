import React from 'react';
import { Paper, Typography } from '@mui/material';

interface AccountDetailsProps {
  accountDetails: string;
  accountAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({
  accountDetails,
  accountAddress,
  city,
  state,
  zip
}) => {
  return (
    <Paper 
      sx={{ 
        p: 2, 
        bgcolor: 'grey.100',
        textAlign: 'center'
      }}
    >
      <Typography variant="subtitle1">{accountDetails}</Typography>

      {accountAddress && (
        <Typography variant="body2">{accountAddress}</Typography>
      )}

      {(city && state && zip) && (
        <Typography variant="body2">
          {city}, {state} {zip}
        </Typography>
      )}
    </Paper>
  );
}; 