'use client'

import React, { useState } from 'react';
import { Box, Alert, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useConsentApi } from 'hooks/useConsentApi';
import { ROUTES } from 'config/routes';
import { CountryCode } from 'libphonenumber-js/types';
import { PersonalInfoSection } from './components/PersonalInfoSection';
import { CreditCardSection } from './components/CreditCardSection';
import { AddressSection } from './components/AddressSection';
import { ContactSection } from './components/ContactSection';
import { ConsentCheckbox } from './components/ConsentCheckbox';
import { useMicroform } from './hooks/useMicroform';
import { useFormValidation } from './hooks/useFormValidation';
import { PaymentFormProps } from './types';
import { commonTextFieldStyles } from './styles';
import { useUrlParams } from 'hooks/useUrlParams';
import { useAuth } from 'react-oidc-context';
import { getPhoneNumberCode } from 'utils/getCountryCodyByPhoneNumber';

export default function PaymentForm({ contextJWT }: PaymentFormProps) {
  const navigate = useNavigate();
  const { submitCardConsent } = useConsentApi();
  const { microform, cardNumberValid, cvvValid, error: microformError, createToken } = useMicroform(contextJWT);
  const { touchedFields, handleBlur, isValidEmail, isValidPhone, isValidForm } = useFormValidation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phoneCountry, setPhoneCountry] = useState<CountryCode>('US');
  const [cardNickname, setCardNickname] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { params } = useUrlParams();
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!microform) return;

    try {
      const phoneNumberCode = getPhoneNumberCode(phoneCountry);
      const phoneNumberWithCountryCode = `${phoneNumberCode} ${phoneNumber}`;

      const transientToken = await createToken({
        expirationMonth: expiryMonth,
        expirationYear: expiryYear
      });

      await submitCardConsent({
        account_number: params.accountNumber,
        cognito_id: auth.user?.profile?.sub as string,
        card_nickname: cardNickname,
        transient_token_jwt: transientToken as string,
        bill_to: {
          first_name: firstName,
          last_name: lastName,
          address1: billingAddress,
          locality: city,
          administrativeArea: state,
          postalCode: zipCode,
          country: country,
          email: email,
          phoneNumber: phoneNumberWithCountryCode
        }
      });
      navigate(ROUTES.CARD_SAVED_SUCCESS);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to process card information');
    }
  };

  const formFields = {
    firstName,
    lastName,
    billingAddress,
    city,
    state,
    zipCode,
    expiryMonth,
    expiryYear,
    country,
    phoneNumber,
    email,
    cardNickname
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      {(error || microformError) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error || microformError}
        </Alert>
      )}

      <PersonalInfoSection
        firstName={firstName}
        lastName={lastName}
        onFirstNameChange={setFirstName}
        onLastNameChange={setLastName}
        onBlur={handleBlur}
        touchedFields={touchedFields}
      />

      <ContactSection
        phoneNumber={phoneNumber}
        email={email}
        phoneCountry={phoneCountry}
        onPhoneNumberChange={setPhoneNumber}
        onEmailChange={setEmail}
        onPhoneCountryChange={setPhoneCountry}
        onBlur={handleBlur}
        touchedFields={touchedFields}
        isValidPhone={isValidPhone}
        isValidEmail={isValidEmail}
      />

      <CreditCardSection
        expiryMonth={expiryMonth}
        expiryYear={expiryYear}
        onExpiryMonthChange={setExpiryMonth}
        onExpiryYearChange={setExpiryYear}
        onBlur={handleBlur}
        touchedFields={touchedFields}
      />

      <TextField
        required
        fullWidth
        label="Card Nickname"
        value={cardNickname}
        onChange={(e) => setCardNickname(e.target.value)}
        onBlur={() => handleBlur('cardNickname')}
        error={touchedFields.cardNickname && !cardNickname}
        helperText={touchedFields.cardNickname && !cardNickname ? 'Required' : ''}
        sx={{ ...commonTextFieldStyles, mt: 2 }}
      />

      <AddressSection
        billingAddress={billingAddress}
        city={city}
        state={state}
        zipCode={zipCode}
        country={country}
        onBillingAddressChange={setBillingAddress}
        onCityChange={setCity}
        onStateChange={setState}
        onZipCodeChange={setZipCode}
        onCountryChange={setCountry}
        onBlur={handleBlur}
        touchedFields={touchedFields}
      />

      <ConsentCheckbox
        agreed={agreed}
        onChange={setAgreed}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!isValidForm(formFields, cardNumberValid, cvvValid, agreed)}
        sx={{ 
          mt: 2,
          py: 1.5,
          backgroundColor: '#1976d2'
        }}
      >
        Submit
      </Button>
    </Box>
  );
}
