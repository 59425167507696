import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useAuthRedirect } from 'hooks/useAuthRedirect';
import { useUserApi } from 'hooks/useUserApi';
import { useUrlParams } from 'hooks/useUrlParams';
import { useAccountDetails } from 'hooks/useAccountDetails';

export default function CallbackPage() {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuthRedirect();
  const { getUser } = useUserApi();
  const { params } = useUrlParams();
  const { saveAccountDetails } = useAccountDetails();

  useEffect(() => {
    if (isLoading) return;

    const getAccountDetails = async () => {
      const user = await getUser(params.accountNumber);
      saveAccountDetails({
        accountDetails: params.accountDetails,
        accountAddress: user.address,
        city: user.city,
        state: user.state,
        zip: user.zip,
        branch: user.branch,
        branchAbbreviation: user.branch_abbreviation,
        customerName: user.customer_name
      });
    };
    
    getAccountDetails();

    if (isAuthenticated) {
      navigate('/consent');
    } else {
      navigate('/');
    }
  }, [isAuthenticated, isLoading, navigate]);

  return <LoadingSpinner />;
} 