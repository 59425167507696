  interface CognitoConfig {
  authority: string;
  client_id: string;
  redirect_uri: string;
  logout_uri: string;
  response_type: string;
  scope: string;
  cognitoDomain: string;
}

export const cognitoConfig: CognitoConfig = {
  authority: process.env.REACT_APP_COGNITO_AUTHORITY || '',
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI || '',
  logout_uri: process.env.REACT_APP_COGNITO_LOGOUT_URI || '',
  response_type: process.env.REACT_APP_COGNITO_RESPONSE_TYPE || '',
  scope: process.env.REACT_APP_COGNITO_SCOPE || '',
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN || ''
} as const;
