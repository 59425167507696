import apiClient from '../api/apiClient';
import { getApiUrl } from '../config/api';
import { useAuth } from 'react-oidc-context';


export const useUserApi = () => {
  const auth = useAuth();

  const getUser = async (accountNumber: string) => {
    const response = await apiClient.get(
      getApiUrl(`/api/catalog/v1/payments/card-consent/customer?account_number=${encodeURIComponent(accountNumber)}`),
      {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`
        }
      }
    );
    return response.data;
  };
    
  return {
    getUser,
  };
};