import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthRedirect } from 'hooks/useAuthRedirect';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';

export const ProtectedRoute: React.FC = () => {
  const { isLoading, isAuthenticated, error } = useAuthRedirect();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    console.error('Auth error:', error);
    return <Navigate to="/error" />;
  }

  if (isAuthenticated && window.location.pathname === '/login') {
    return <Navigate to="/" />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}; 