import React from 'react';
import { Grid, TextField } from '@mui/material';
import { commonTextFieldStyles } from '../styles';

interface PersonalInfoSectionProps {
  firstName: string;
  lastName: string;
  onFirstNameChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
  onBlur: (field: string) => void;
  touchedFields: Record<string, boolean>;
}

export const PersonalInfoSection: React.FC<PersonalInfoSectionProps> = ({
  firstName,
  lastName,
  onFirstNameChange,
  onLastNameChange,
  onBlur,
  touchedFields
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => onFirstNameChange(e.target.value)}
          onBlur={() => onBlur('firstName')}
          error={touchedFields.firstName && !firstName}
          helperText={touchedFields.firstName && !firstName ? 'Required' : ''}
          sx={commonTextFieldStyles}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          label="Last Name"
          value={lastName}
          onChange={(e) => onLastNameChange(e.target.value)}
          onBlur={() => onBlur('lastName')}
          error={touchedFields.lastName && !lastName}
          helperText={touchedFields.lastName && !lastName ? 'Required' : ''}
          sx={commonTextFieldStyles}
        />
      </Grid>
    </Grid>
  );
}; 