import React from 'react';
import { MainLayout } from 'components/layouts/MainLayout';
import { Typography, Paper, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function CardSavedSuccessPage() {
  return (
    <MainLayout>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
      }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            maxWidth: 500, 
            width: '100%',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: 1
          }}
        >
          <CheckCircleIcon 
            sx={{ 
              fontSize: 128, 
              color: 'success.main',
              mb: 3 
            }} 
          />
          <Typography variant="h6" gutterBottom>
            Your credit card information
          </Typography>
          <Typography variant="h6" gutterBottom>
            has been saved
          </Typography>
        </Paper>
      </Box>
    </MainLayout>
  );
} 