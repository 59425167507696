import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from 'components/auth/ProtectedRoute';
import ConsentPage from './pages/ConsentPage';
import ContactSupport from './pages/ContactSupport';
import LoginPage from './pages/LoginPage';
import HomePage from 'pages/HomePage';
import CallbackPage from 'pages/CallbackPage';
import CardSavedSuccessPage from 'pages/CardSavedSuccessPage';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/contact-support" element={<ContactSupport />} />
      <Route path="/callback" element={<CallbackPage />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/consent" element={<ConsentPage />} />
        <Route path="/card-saved-success" element={<CardSavedSuccessPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
