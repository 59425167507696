import { useAuth } from "react-oidc-context";
import { cognitoConfig } from "config/cognito";

export const useAuthRedirect = () => {
  const auth = useAuth();

  const signOutRedirect = async () => {
    try {
      await auth.removeUser();
      sessionStorage.clear();
      
      const logoutUrl = new URL(`${cognitoConfig.cognitoDomain}/logout`);
      logoutUrl.searchParams.set('client_id', cognitoConfig.client_id);
      logoutUrl.searchParams.set('logout_uri', cognitoConfig.logout_uri);
      logoutUrl.searchParams.set('response_type', cognitoConfig.response_type);

      window.location.href = logoutUrl.toString();
    } catch (error) {
      console.error('Error during sign out:', error);
      window.location.href = '/contact-support';
    }
  };

  const signinRedirect = () => {
    auth.signinRedirect();
  };

  return {
    ...auth,
    signOutRedirect,
    signinRedirect,
    isLoading: auth.isLoading,
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,
    error: auth.error,
  };
}; 