import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

interface ConsentCheckboxProps {
  agreed: boolean;
  onChange: (checked: boolean) => void;
}

export const ConsentCheckbox: React.FC<ConsentCheckboxProps> = ({
  agreed,
  onChange
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox 
          checked={agreed}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label="I authorize Fisher Auto Parts, Inc. to charge my credit card for agreed upon purchases. I understand that my information will be saved to file for future transactions on my account."
      sx={{ mt: 2, mb: 2 }}
    />
  );
}; 