import React, { useState } from 'react';
import { Grid, TextField, Select, MenuItem, Box, InputBase } from '@mui/material';
import { commonTextFieldStyles } from '../styles';
import { PHONE_COUNTRY_CODES } from '../constants';
import { CountryCode } from 'libphonenumber-js/types';

interface ContactSectionProps {
  phoneNumber: string;
  email: string;
  phoneCountry: CountryCode;
  onPhoneNumberChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  onPhoneCountryChange: (value: CountryCode) => void;
  onBlur: (field: string) => void;
  touchedFields: Record<string, boolean>;
  isValidPhone: (phone: string, phoneCountry: CountryCode) => boolean;
  isValidEmail: (email: string) => boolean;
}

export const ContactSection: React.FC<ContactSectionProps> = ({
  phoneNumber,
  email,
  phoneCountry,
  onPhoneNumberChange,
  onEmailChange,
  onPhoneCountryChange,
  onBlur,
  touchedFields,
  isValidPhone,
  isValidEmail
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredCountries = PHONE_COUNTRY_CODES.filter(country => 
    country.name.toLowerCase().includes(searchTerm) || 
    country.code.toLowerCase().includes(searchTerm)
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) return;
    
    const key = event.key.toLowerCase();
    if (key.length === 1 && /[a-z]/.test(key)) {
      const countryIndex = PHONE_COUNTRY_CODES.findIndex(country => 
        country.name.toLowerCase().startsWith(key)
      );
      if (countryIndex !== -1) {
        const selectElement = document.getElementById('country-select');
        const optionElement = selectElement?.querySelector(`[data-value="${PHONE_COUNTRY_CODES[countryIndex].code}"]`);
        optionElement?.scrollIntoView({ block: 'nearest' });
      }
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Select
              id="country-select"
              fullWidth
              value={phoneCountry}
              onChange={(e) => onPhoneCountryChange(e.target.value as CountryCode)}
              onOpen={() => setIsOpen(true)}
              onClose={() => {
                setIsOpen(false);
                setSearchTerm('');
              }}
              onKeyDown={handleKeyDown}
              renderValue={(value) => {
                const country = PHONE_COUNTRY_CODES.find(country => country.code === value);
                return country?.label || '+1';
              }}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 300 }
                }
              }}
              sx={{
                height: '56px',
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
            >
              <MenuItem sx={{ p: 0 }}>
                <InputBase
                  placeholder="Search country..."
                  value={searchTerm}
                  onChange={handleSearch}
                  onClick={(e) => e.stopPropagation()}
                  sx={{ 
                    px: 2, 
                    py: 1,
                    width: '100%',
                    '& input': {
                      padding: 0
                    }
                  }}
                />
              </MenuItem>
              {(searchTerm ? filteredCountries : PHONE_COUNTRY_CODES).map(({ code, label, name }) => (
                <MenuItem key={code} value={code} data-value={code}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    '& .country-code': {
                      minWidth: '45px'
                    },
                    '& .country-name': {
                      ml: 1,
                      color: 'text.secondary'
                    }
                  }}>
                    <span className="country-code">{label}</span>
                    <span className="country-name">{name}</span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={8}>
            <TextField
              required
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => onPhoneNumberChange(e.target.value)}
              onBlur={() => onBlur('phoneNumber')}
              error={touchedFields.phoneNumber && !isValidPhone(phoneNumber, phoneCountry)}
              helperText={touchedFields.phoneNumber && !isValidPhone(phoneNumber, phoneCountry)
                ? 'Please enter a valid phone number'
                : ''}
              placeholder="Enter phone number"
              sx={commonTextFieldStyles}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => onEmailChange(e.target.value)}
          onBlur={() => onBlur('email')}
          error={touchedFields.email && !isValidEmail(email)}
          helperText={touchedFields.email && !isValidEmail(email)
            ? 'Please enter a valid email address'
            : ''}
          sx={commonTextFieldStyles}
        />
      </Grid>
    </Grid>
  );
}; 