import React from 'react';
import { Typography, Paper } from '@mui/material';
import { MainLayout } from 'components/layouts/MainLayout';

export default function ContactSupport() {
  return (
    <MainLayout>
      <Paper sx={{ p: 4, maxWidth: 600, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Missing Account Information
        </Typography>
        <Typography>
          Please contact your Fisher Auto Parts account representative for more information about connecting to the My Fisher Auto Parts Website.
        </Typography>
      </Paper>
    </MainLayout>
  );
} 