export const commonTextFieldStyles = {
  '& .MuiOutlinedInput-root': {
    height: '56px',
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.Mui-focused, &.MuiFormLabel-filled': {
      transform: 'translate(14px, -9px) scale(0.75)',
    }
  }
};

export const commonMicroformStyles = {
  height: '56px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  px: 2,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'transparent',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    transition: 'border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&.flex-microform-focused': {
    borderColor: '#1976d2',
    borderWidth: 2,
    '& iframe': {
      transform: 'translateY(-1px)'
    },
    '& .microform-label': {
      color: '#1976d2',
      transform: 'translate(14px, -9px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 4px',
    }
  },
  '& .microform-label': {
    position: 'absolute',
    top: -9,
    left: -4,
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1rem',
    transform: 'translate(14px, -9px) scale(0.75)',
    transformOrigin: 'top left',
    backgroundColor: '#fff',
    padding: '0 4px',
    transition: 'color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  }
};