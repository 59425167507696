import apiClient from '../api/apiClient';
import { getApiUrl } from '../config/api';
import { useAuth } from 'react-oidc-context';

export type SubmitCardConsentBody = {
  account_number: string;
  cognito_id: string;
  card_nickname: string;
  transient_token_jwt: string;
  bill_to: {
    first_name: string;
    last_name: string;
    address1: string;
    locality: string;
    administrativeArea: string;
    postalCode: string;
    country: string;
    email: string;
    phoneNumber: string;
  };
};

export const useConsentApi = () => {
  const auth = useAuth();

  const getCyberSourceJwt = async (accountNumber: string) => {
    const response = await apiClient.get(
      getApiUrl(`/api/catalog/v1/payments/card-consent/context?account_number=${encodeURIComponent(accountNumber)}`),
      {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`
        }
      }
    );
    return response.data;
  };


  const submitCardConsent = async (body: SubmitCardConsentBody) => {
    const response = await apiClient.post(getApiUrl('/api/catalog/v1/payments/card-consent/tms-token'), body, {
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`
      }
    });
    return response.data;
  };
    
  return {
    getCyberSourceJwt,
    submitCardConsent
  };
};